import { getCookie } from "@utils/cookie";
import { AxiosInstance } from "axios";
import axios from "axios";

const NetworkClient = (function () {
  let instance: AxiosInstance;

  return {
    getInstance: function () {
      if (!instance) {
        const accessToken = getCookie("access_token");

        instance = axios.create({
          baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        });
      }

      instance.interceptors.request.use(async (config) => {
        const accessToken = await Promise.resolve(getCookie("access_token"));

        config.headers = accessToken
          ? {
              Authorization: `Bearer ${accessToken}`
            }
          : {};
        return config;
      });
      return instance;
    }
  };
})();

export default NetworkClient.getInstance();
