import React, { useMemo } from "react";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import networkClient from "@network/networkClient";

import useSnackbar from "./useSnackbar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = () => {
  const {
    isSnackbarShown,
    handleCloseSnackbar,
    severity,
    message,
    openSnackbar
  } = useSnackbar();

  useMemo(() => {
    networkClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        const response = err.response;
        if (response) {
          const { status } = response;
          if (response.data && status) {
            const { message } = response.data;

            if (status === 401 || status === 460) {
              return Promise.reject(err);
            }

            openSnackbar(`[${status}] ${message}`, "error");
          }
        }
        return Promise.reject(err);
      }
    );
  }, [openSnackbar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isSnackbarShown}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
