import { atom } from "recoil";
import { Severity } from "types/snackbar.types";

export const isSnackbarShownState = atom({
  default: false,
  key: "IsSnackbarShownState"
});

export const messageState = atom({
  default: "",
  key: "MessageState"
});

export const severityState = atom<Severity>({
  default: "error",
  key: "SeverityState"
});
