import { useState, useEffect, ReactElement } from "react";

import {
  REDIRECT_TO_LOGIN_ALERT_TEXT,
  REFRESH_PAGE_ALERT_TEXT
} from "@constants/auth";
import useAlert from "@hooks/useAlert";
import accountApi from "@network/account/accountApi";
import networkClient from "@network/networkClient";
import { accountState } from "@store/recoil/accountRecoil";
import { getCookie, removeAllCookies, setCookie } from "@utils/cookie";
import { useRouter } from "next/router";
import { useRecoilState } from "recoil";

type Props = {
  children: ReactElement | ReactElement[];
};

const AuthProvider = ({ children }: Props) => {
  const router = useRouter();
  const [isVerified, setIsVerified] = useState(false);
  const [account, setAccountState] = useRecoilState(accountState);
  const { showAlert } = useAlert();

  const accessToken = getCookie("access_token");
  const refreshToken = getCookie("refresh_token");

  const redirectToLogin = () => {
    showAlert({ content: REDIRECT_TO_LOGIN_ALERT_TEXT });

    window.location.href = "/login";
  };

  const refreshPage = () => {
    showAlert({ content: REFRESH_PAGE_ALERT_TEXT });

    window.location.reload();
  };

  useEffect(() => {
    const setAccountStateFromAuthResponse = async () => {
      const data = await accountApi().getInfo();

      const { id, name, in_testing, level, role } = data;
      setAccountState({ id, name, in_testing, level, role });
    };

    if (accessToken && !account) {
      setAccountStateFromAuthResponse();
    }

    // 리프레시 토큰용 쿠키는 만료기간이 정해져있지 않아서 수동으로 지우지 않는 이상 아래 로직이 수행될 일은 없겠지만,
    // 그래도 리프레시 토큰용 쿠키가 만료되어 없을때는 로그인으로 리디렉션 처리
    if (!refreshToken) {
      if (router.pathname !== "/login") {
        showAlert({ content: REDIRECT_TO_LOGIN_ALERT_TEXT });

        router.replace("/login");
      }
    }

    networkClient.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const { response } = error;

        if (response) {
          if (
            response.status === 400 &&
            response.data.message === "리프레쉬 토큰이 유효하지 않습니다."
          ) {
            removeAllCookies();
            window.location.href = "/login";
          }

          if (response.status === 401 || response.status === 460) {
            const refresh_token = getCookie("refresh_token");

            if (refresh_token) {
              accountApi()
                .signin({ grant_type: "refresh_token", refresh_token })
                .then(async (response) => {
                  removeAllCookies();

                  if (response && response.status === 200) {
                    const { token } = response.data;

                    setCookie("access_token", token.access_token, {
                      path: "/",
                      secure: true,
                      maxAge: token.expires_in
                    });

                    setCookie("refresh_token", token.refresh_token, {
                      path: "/",
                      secure: true
                    });

                    refreshPage();
                    return networkClient.request(response.config);
                  } else {
                    redirectToLogin();

                    return Promise.reject(error);
                  }
                });
            } else {
              redirectToLogin();

              return Promise.reject(error);
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (account) {
      setIsVerified(true);
    }
  }, [account]);

  // * 액세스 토큰용 쿠키가 만료되고 유저가 페이지를 새로고침할 시 화면은 보여주고 networkClient.ts 파일 내의 로직(리프레시 토큰으로 재인증)이 실행되도록 하기 위한 코드
  useEffect(() => {
    if (!accessToken && refreshToken) {
      setIsVerified(true);
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (router.pathname === "/login" && accessToken) {
      router.replace("/");
    }
  }, [router.pathname]);

  if (router.pathname === "/login") return <>{children}</>;

  return <>{isVerified && children}</>;
};

export default AuthProvider;
