import networkClient from "./networkClient";

export function getFetcher<T>(url: string, params?: Record<string, any>) {
  return networkClient.get<T | any>(url, { params }).then((res) => res.data);
}

export function putFetcher<T>(url: string, params?: Record<string, any>) {
  return networkClient.put<T | any>(url, params).then((res) => res.data);
}

export function patchFetcher<T>(url: string, params?: Record<string, any>) {
  return networkClient.patch<T | any>(url, params).then((res) => res.data);
}

export function postFetcher<T>(url: string, params?: Record<string, any>) {
  return networkClient.post<T | any>(url, params).then((res) => res.data);
}

export function deleteFetcher<T>(url: string, params?: Record<string, any>) {
  return networkClient.delete<T | any>(url, params).then((res) => res.data);
}
