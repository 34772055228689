import { atom } from "recoil";
import { AlertDialog } from "types/alert.types";

export const alertState = atom<AlertDialog>({
  default: {
    open: false,
    title: null,
    content: null
  },
  key: "alertState"
});
