import { useState, useEffect } from "react";

import { alertState } from "@store/recoil/alertRecoil";
import { useRecoilState } from "recoil";
import { AlertMessage } from "types/alert.types";

const useAlert = () => {
  const [alertDialog, setAlertDialog] = useRecoilState(alertState);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const reset = () => {
    setAlertDialog({ content: "", open: false });
    setNeedsCleanup(false);
  };

  const showAlert = ({ title, content }: AlertMessage) => {
    setAlertDialog({
      open: true,
      title,
      content
    });
    setNeedsCleanup(true);
  };

  const hideAlert = () => {
    reset();
  };

  useEffect(() => {
    return () => {
      // alert dialog가 뒤로가기시에도 살아있지 않도록 클린업에서 처리
      if (needsCleanup) {
        hideAlert();
      }
    };
  }, [needsCleanup]);

  return {
    ...alertDialog,
    showAlert,
    hideAlert
  };
};

export default useAlert;
