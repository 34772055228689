import React, { useEffect } from "react";

import { datadogRum } from "@datadog/browser-rum";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { removeCookie } from "@utils/cookie";
import { AxiosError } from "axios";
import { useRouter } from "next/router";

export default function RetryError({
  resetErrorBoundary,
  error
}: {
  resetErrorBoundary: (...args: any[]) => void;
  error: Error | AxiosError;
}) {
  const router = useRouter();

  const handleRoutePageWithoutQuery = () => {
    router.replace(router.pathname);
    // router path가 바로 반영되지 않기 때문에 timeout 사용
    const timerId = setTimeout(() => {
      router.reload();
    }, 100);

    return () => {
      clearTimeout(timerId);
    };
  };

  const handleGoToLoginPage = () => {
    removeCookie("refresh_token");
    removeCookie("access_token");
    window.location.href = "/login";
  };

  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      mt={10}
    >
      <Typography> 데이터를 불러오는데 실패하였습니다. </Typography>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleRoutePageWithoutQuery} variant="contained">
          페이지로 돌아가기
        </Button>
        <Button onClick={() => resetErrorBoundary()} variant="outlined">
          다시 시도
          <RefreshIcon color="primary" sx={{ ml: 1 }} />
        </Button>
        <Button onClick={handleGoToLoginPage}>로그인 페이지로 가기</Button>
      </Stack>
    </Stack>
  );
}
