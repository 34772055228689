import {
  isSnackbarShownState,
  messageState,
  severityState
} from "@store/recoil/snackbarRecoil";
import { useRecoilState } from "recoil";
import { Severity } from "types/snackbar.types";

const useSnackbar = () => {
  const [isSnackbarShown, setIsSnackbarState] =
    useRecoilState(isSnackbarShownState);
  const [message, setMessage] = useRecoilState(messageState);
  const [severity, setSeverity] = useRecoilState(severityState);

  const handleCloseSnackbar = () => {
    setIsSnackbarState(false);
  };

  const openSnackbar = (message: string, severity: Severity) => {
    setSeverity(severity);
    setMessage(message);
    setIsSnackbarState(true);
  };

  return {
    isSnackbarShown,
    severity,
    message,
    openSnackbar,
    handleCloseSnackbar
  };
};

export default useSnackbar;
