import { useState, useEffect } from "react";

import { defaultConfirmText } from "@constants/confirm";
import { confirmState } from "@store/recoil/confirmRecoil";
import { useRecoilState } from "recoil";
import { ConfirmMessage } from "types/confirm.types";

const useConfirm = () => {
  const [confirmDialog, setConfirmDialog] = useRecoilState(confirmState);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const reset = () => {
    setConfirmDialog({ content: "", proceed: null, cancel: null, open: false });
    setNeedsCleanup(false);
  };

  const showConfirm = (message?: ConfirmMessage) => {
    const promise = new Promise((resolve, reject) => {
      setConfirmDialog({
        content: message?.content ?? defaultConfirmText.content,
        title: message?.title ?? defaultConfirmText.title,
        cancelText: message?.cancelText ?? defaultConfirmText.cancelText,
        proceedText: message?.proceedText ?? defaultConfirmText.proceedText,
        open: true,
        proceed: resolve,
        cancel: reject
      });
      setNeedsCleanup(true);
    });

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  useEffect(() => {
    return () => {
      // confirm dialog가 뒤로가기시에도 살아있지 않도록 클린업에서 처리
      if (confirmDialog.cancel && needsCleanup) {
        confirmDialog.cancel();
      }
    };
  }, [showConfirm, needsCleanup]);

  return {
    ...confirmDialog,
    showConfirm
  };
};

export default useConfirm;
