import { defaultConfirmText } from "@constants/confirm";
import { atom } from "recoil";
import { ConfirmDialog } from "types/confirm.types";

export const confirmState = atom<ConfirmDialog>({
  default: {
    ...defaultConfirmText,
    open: false,
    cancel: null,
    proceed: null
  },
  key: "confirmState"
});
