// src/datadog.nextjs.ts
import { datadogRum } from "@datadog/browser-rum";
function datadogNextjs(option) {
  const initDatadog = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
      datadogRum.init({
        applicationId: (_a = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) != null ? _a : "",
        clientToken: (_b = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) != null ? _b : "",
        site: "datadoghq.com",
        service: (_c = process.env.NEXT_PUBLIC_DATADOG_APPLICATION) != null ? _c : "",
        env: (_d = process.env.NEXT_PUBLIC_DATADOG_ENV) != null ? _d : "",
        version: (_e = process.env.NEXT_PUBLIC_RELEASE_VERSION) != null ? _e : "",
        sessionSampleRate: (_f = option == null ? void 0 : option.sessionSampleRate) != null ? _f : 100,
        sessionReplaySampleRate: (_g = option == null ? void 0 : option.sessionReplaySampleRate) != null ? _g : 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
          `${process.env.NEXT_PUBLIC_API_DOMAIN}`,
          `${process.env.NEXT_PUBLIC_CDN_DOMAIN}`
        ]
      });
      datadogRum.startSessionReplayRecording();
    }
  };
  return {
    initDatadog
  };
}

// src/datadog.nuxtjs.ts
import { datadogRum as datadogRum2 } from "@datadog/browser-rum";
function datadogNuxtjs() {
  const initDatadog = () => {
    var _a, _b, _c, _d;
    if (process.env.DATADOG_APPLICATION_ID) {
      datadogRum2.init({
        applicationId: (_a = process.env.DATADOG_APPLICATION_ID) != null ? _a : "",
        clientToken: (_b = process.env.DATADOG_CLIENT_TOKEN) != null ? _b : "",
        site: "datadoghq.com",
        service: (_c = process.env.DATADOG_APPLICATION) != null ? _c : "",
        env: (_d = process.env.DATADOG_ENV) != null ? _d : "",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
          `${process.env.API_DOMAIN}`,
          `${process.env.VEHICLE_API_DOMAIN}`,
          `${process.env.CDN_DOMAIN}`
        ],
        defaultPrivacyLevel: "mask-user-input",
        traceSampleRate: 100
      });
      datadogRum2.startSessionReplayRecording();
    }
  };
  return {
    initDatadog
  };
}
export {
  datadogNextjs,
  datadogNuxtjs
};
