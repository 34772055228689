import { getFetcher, postFetcher } from "@network/fetchers";
import { SigninParams, PostAccountSigninResponse } from "types/account.types";

function signin(params: SigninParams) {
  const { grant_type, phone, password, refresh_token } = params;

  if (params.grant_type === "password") {
    return postFetcher<PostAccountSigninResponse>("/account/signin", {
      grant_type,
      phone,
      password,
      app: 2
    });
  }

  return postFetcher<PostAccountSigninResponse>("/account/signin", {
    grant_type,
    refresh_token,
    app: 2
  });
}

function signout() {
  return postFetcher("/account/signout");
}

function getInfo() {
  return getFetcher("/account/info");
}

const accountApi = () => {
  return {
    signin,
    signout,
    getInfo
  };
};

export default accountApi;
