import { atom } from "recoil";

type AccountState = {
  id: number;
  name: string;
  in_testing: boolean;
  level: number;
  role: string;
};

export const accountState = atom<undefined | AccountState>({
  default: undefined,
  key: "AccountState"
});
