import React from "react";

import AlertDialog from "@components/AlertDialog";
import AlertSnackbar from "@components/AlertSnackbar";
import AuthProvider from "@components/AuthProvider";
import ConfirmDialog from "@components/ConfirmDialog";
import RetryError from "@components/RetryError";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { datadogNextjs } from "datadog-config";
import type { AppProps } from "next/app";
import Script from "next/script";
import { CookiesProvider } from "react-cookie";
import { ErrorBoundary } from "react-error-boundary";
import { RecoilRoot } from "recoil";
import { NextPageWithLayout } from "types/layout.types";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      staleTime: 1000
    },
    mutations: {
      useErrorBoundary: true
    }
  }
});

// datadog setting
const { initDatadog } = datadogNextjs();
initDatadog();

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#3874CB"
    }
  }
});

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function FranchiseApp({ Component, pageProps }: AppPropsWithLayout) {
  const { reset } = useQueryErrorResetBoundary();

  const getLayout = Component.getLayout || ((page) => <>{page}</>);

  return (
    <>
      <Script
        strategy="beforeInteractive"
        src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ff8eexa6ic&submodules=drawing"
      />
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={mdTheme}>
            <RecoilRoot>
              <ErrorBoundary
                onReset={reset}
                fallbackRender={({ resetErrorBoundary, error }) => (
                  <RetryError
                    resetErrorBoundary={resetErrorBoundary}
                    error={error}
                  />
                )}
              >
                <AuthProvider>
                  {getLayout(<Component {...pageProps} />)}
                  <AlertSnackbar />
                  <AlertDialog />
                  <ConfirmDialog />
                </AuthProvider>
              </ErrorBoundary>
            </RecoilRoot>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </>
  );
}
export default FranchiseApp;
